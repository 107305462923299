// Write your javascript here...
// ES6 will be compiled with Webpack

import lozad from "lozad";
import hljs from "highlight.js";
import markdownit from "markdown-it";

const supportsLazyLoad = "loading" in document.createElement("img");

const supportsIntersectionObserver =
  "IntersectionObserver" in window &&
  "IntersectionObserverEntry" in window &&
  "intersectionRatio" in window.IntersectionObserverEntry.prototype;

const images = document.querySelectorAll("main article img");
if (supportsLazyLoad || !supportsIntersectionObserver) {
  // If the browser supports native lazy loading
  // or doesn't support interSection observer
  // set the src and let the browser handle it
  images.forEach(node => {
    node.setAttribute("src", node.getAttribute("data-src"));
    node.removeAttribute("data-src");
    // node.removeAttribute("loading"); // Remove lazy loading
  });
} else {
  // If the browser supports interSection observer
  // but not native lazy loading let's polyfill
  const observer = lozad(images);
  observer.observe();
}

window.addEventListener("DOMContentLoaded", () => {

  // Smooth scroll anchor links
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });

  const state = {
    shake_timeout: undefined,
  };

  if (document.querySelector("form#contact")) {

    document.querySelector("form#contact").addEventListener("submit", e => {
      e.preventDefault();

      const form = e.target;
      const name = form.querySelector("input[name=name]");
      const email = form.querySelector("input[name=email]");
      const email_address = email.value.trim();
      const message = form.querySelector("textarea[name=message]");

      document.querySelectorAll('.field').forEach(field => {
        field.classList.remove("shake");
      });

      if (state.shake_timeout !== undefined) clearTimeout(state.shake_timeout);

      function shoogleField(field) {
        field.classList.add("shake");
        state.shake_timeout = setTimeout(function () {
          field.classList.remove("shake");
        }, 830);
      }

      const emailRegex = RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/); // https://ui.dev/validate-email-address-javascript/

      if (!name.value.trim().length) {
        name.value = "";
        name.focus();
        setTimeout(() => {
            shoogleField(name);
        }, 200);
        return;
      } else if (!email_address.length) {
        email.value = "";
        email.focus();
        setTimeout(() => {
            shoogleField(email);
        }, 200);
        return;
      } else if (!emailRegex.test(email_address)) {
        email.value = email.value.trim();
        email.focus();
        email.select();
        setTimeout(() => {
            shoogleField(email);
        }, 200);
        return;
      } else if (!message.value.trim().length) {
        message.value = "";
        message.focus();
        setTimeout(() => {
            shoogleField(message);
        }, 200);
        return;
      }

      // Trim input
      document.querySelectorAll('.field').forEach(field => {
        field.value = field.value.trim();
      });

      const request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          form.querySelector(".form-contents").innerHTML = "<p>Thank you for your message.</p>";
        } else if (this.readyState == 4) {
          form.querySelector(".form-contents").innerHTML =
            "<p>😬 Sorry, there was an error.<br>Maybe refresh the page and try again?</p>";
        }
      };
      request.open("POST", "https://www.jamieonkeys.dev", true);
      request.setRequestHeader(
        "Content-type",
        "application/x-www-form-urlencoded"
      );
      request.send(
        "form-name=" +
          encodeURIComponent(
            form.querySelector("input[name='form-name']").value
          ) +
          "&name=" +
          encodeURIComponent(form.querySelector("input[name=name]").value) +
          "&email=" +
          encodeURIComponent(form.querySelector("input[name=email]").value) +
          "&message=" +
          encodeURIComponent(form.querySelector("textarea[name=message]").value) +
          "&bot-field=" +
          encodeURIComponent(
            form.querySelector("input[name='bot-field']").value
          )
      );
    });
  }
});

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const urlRegEx = /(\b(https):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi; // Only transform https URLs. Source: https://www.codespeedy.com/replace-url-with-clickable-link-javascript/
const pins = document.getElementById('pins') || document.getElementById('pins-detailed');

if (!/bot|spider|crawl/i.test(navigator.userAgent) && location.hostname.indexOf('jamieonkeys.dev') > -1) {
  setTimeout(() => {
    // Usage stats
    fetch(`https://www.jamieonkeys.dev/stats?page=${document.location.pathname}&ref1=${window.frames.top.document.referrer}&ref2=${document.referrer}`).then(response => {
      // Success
      if (response.ok)
        return response.json(); // Returns to then()

      // Error
      return Promise.reject(response); // Returns to catch()

    }).then(json => { }).catch(error => {
      console.warn(error);
    });
  }, 3000);
}

/**
 * Pinboard pins
 */

// if (pins) {
//   const pinsDetailed = pins.getAttribute('id') === 'pins' ? false : true;
//   const pinsLimit = !pinsDetailed ? 20 : 40;
//
//   fetch(`https://www.jamieonkeys.dev/getpins?count=${pinsLimit}`).then(function (response) {
//
//     // Success
//     if (response.ok)
//       return response.json(); // Returns to then()
//
//     // Error
//     return Promise.reject(response);
//
//   }).then(function (data) {
//
//     function addToUI() {
//
//       const items = [];
//
//       for (let i = 0; i < pinsLimit; i++) {
//         if (data[i]) {
//           const date = new Date(data[i].dt);
//           const dateFormatted = `${date.getDate()} ${months[date.getMonth()]}`;
//           const verb = !i ? 'Pinned ' : '';
//           const year = !i ? ` ${date.getFullYear()}` : '';
//           const hide = i > pinsLimit / 2 - 1 && !pinsDetailed ? ' class="hide fade"' : ''; // Show 10 pins initially
//
//           if (pinsDetailed) {
//             let description = data[i].n.trim().length ? `<div>${data[i].n}</div>` : '';
//
//             // Transform links
//             description = description.replace(urlRegEx, function (match) {
//               return `<a href="${match}">${match}</a>`;
//             });
//
//             // Transform «» to <blockquote></blockquote>
//             description = description.replaceAll(/«(.+?)»/gis, function(match) { // `s` == `dotAll` property == include line breaks
//               return `<blockquote>‘${match.slice(2).slice(0, -2)}’</blockquote>`;
//             });
//
//             items.push(`<div${hide}><a href="${data[i].u}"><h2 class="h5 display-inline">${data[i].d}</h2></a> <span class="dt-published post-meta date-nudge">${verb}${dateFormatted}${year}</span>${description}</div>`);
//           } else {
//             items.push(`<li${hide}><a href="${data[i].u}">${data[i].d}</a> <span class="dt-published post-meta date-nudge">${verb}${dateFormatted}${year}</span></li>`);
//           }
//
//           if (!pinsDetailed && i === pinsLimit / 2 - 1) {
//             items.push('<button id="more-pins" class="button mt-2">More</button>');
//           }
//
//         } else
//           break;
//       }
//
//       if (!pinsDetailed) {
//         const html = `
//           <h2><svg width="24" height="24" viewBox="0 -2 26 26" stroke-width="1.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 14.5L3 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.00007 9.48528L14.1925 18.6777L15.8895 16.9806L15.4974 13.1944L21.0065 8.5211L15.1568 2.67141L10.4834 8.18034L6.69713 7.78823L5.00007 9.48528Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>Pins</h2>
//           <div class="post-meta mt-1">A few recent Web/tech links from my <a href="https://pinboard.in">Pinboard</a>.</div>
//           <ul class="pins">
//             ${items.join('')}
//           </ul>
//         `;
//
//         pins.innerHTML = html;
//
//         const morePins = document.getElementById('more-pins');
//         if (morePins) {
//           morePins.addEventListener('click', e => {
//             document.querySelectorAll('ul.pins li.hide').forEach(hiddenItem => {
//               hiddenItem.classList.remove('hide');
//               setTimeout(() => {
//                 hiddenItem.classList.add('show');
//               }, 60);
//             });
//
//             e.target.parentNode.removeChild(e.target);
//           });
//         }
//       } else {
//         const html = `
//           <div class="post-meta mb-3">Interesting Web/tech-related links from my <a href="https://pinboard.in">Pinboard</a>. <a href="https://feeds.pinboard.in/rss/u:jamieonkeys/t:blogshare/">RSS</a>.</div>
//           <div class="pins pins-more-space">
//             ${items.join('')}
//           </div>
//         `;
//
//         pins.innerHTML = html;
//       }
//
//       // Show pins
//       pins.classList.remove('hide');
//       setTimeout(() => {
//         pins.classList.add('show');
//       }, 60);
//     }
//
//     if (document.readyState === 'loading')
//       document.addEventListener('DOMContentLoaded', addToUI);
//     else // `DOMContentLoaded` has already fired
//       addToUI();
//
//   }).catch(function (err) {
//     console.error(err); // Error
//   });
// }

/**
 * GitHub gists
 */

const gists = document.getElementById('gists');

if (gists) {

  // https://stackoverflow.com/a/6234804
  function escapeHtml(unsafe) {
    return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
  }

  fetch('https://www.jamieonkeys.dev/getgists').then(function (response) {

    if (response.ok)
      return response.json();

    return Promise.reject(response);

  }).then(function (data) {

    if (data.error) {
      console.error(data.error);
      return;
    }

    const gistsLimit = 10; // Maximum number of Gists to add to UI
    const hideIds = [ // IDs of Gists to exclude
      '2369abb83a0f3d53fbc3aba963e80f7c', // PDF page numbers
      'bfbda44e3bb5c2883a25acc5a759c8fc', // Bootstrap 5 colour gradient
      'ab4e15be962602b1bf4975b912b14939', // Apple Music shortcuts
    ];

    // Format each Gists and add to `items`
    function addToUI() {

      const items = [];
      const dataFiltered = data.filter(gist => hideIds.indexOf(gist.id) === -1);

      for (let i = 0; i < gistsLimit; i++) {
        if (dataFiltered[i]) {
          const date = new Date(dataFiltered[i].created_at);
          const dateFormatted = `${date.getDate()} ${months[date.getMonth()]}`;
          const verb = !i ? 'Created ' : '';
          const year = ` ’${date.getFullYear().toString().slice(-2)}`;
          const hide = i > gistsLimit / 2 - 1 ? ' class="hide fade"' : ''; // Show 5 gists initially

          let description = dataFiltered[i].description.trim().length ? `<div>${dataFiltered[i].description}</div>` : '';

          // Transform links
          description = description.replace(urlRegEx, function (match) {
            return `<a href="${match}">${match}</a>`;
          });

          // Transform `` into <code></code>
          description = description.replaceAll(/`(.+?)`/gi, function(match) {
            return `<code>${match.slice(1).slice(0, -1)}</code>`;
          });

          items.push(
            `<div${hide}>
              <a href="${dataFiltered[i].html_url}"><h3 class="h5 display-inline">${Object.keys(dataFiltered[i].files)[0]}</h3></a> <span class="dt-published post-meta date-nudge">${verb}${dateFormatted} ${year}</span>${description}
              <button class="get-gist display-block button button-sm mt-1" data-gist-id="${dataFiltered[i].id}">Show</button>
              <div id="gist-${dataFiltered[i].id}" class="gist-content hide fade"></div>
            </div>`
          );

          if (i === gistsLimit / 2 - 1) {
            items.push('<button id="more-gists" class="button mt-2">More</button>');
          }
        } else
          break;
      }

      const html = `
        <h2 class="h1"><svg width="43" height="43" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg" style="line-height: 7;" viewBox="0 -1 26 23"> <path d="M16 22.0268V19.1568C16.0375 18.68 15.9731 18.2006 15.811 17.7506C15.6489 17.3006 15.3929 16.8902 15.06 16.5468C18.2 16.1968 21.5 15.0068 21.5 9.54679C21.4997 8.15062 20.9627 6.80799 20 5.79679C20.4558 4.5753 20.4236 3.22514 19.91 2.02679C19.91 2.02679 18.73 1.67679 16 3.50679C13.708 2.88561 11.292 2.88561 8.99999 3.50679C6.26999 1.67679 5.08999 2.02679 5.08999 2.02679C4.57636 3.22514 4.54413 4.5753 4.99999 5.79679C4.03011 6.81549 3.49251 8.17026 3.49999 9.57679C3.49999 14.9968 6.79998 16.1868 9.93998 16.5768C9.61098 16.9168 9.35725 17.3222 9.19529 17.7667C9.03334 18.2112 8.96679 18.6849 8.99999 19.1568V22.0268" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 20.0267C6 20.9999 3.5 20.0267 2 17.0267" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>Gists</h2>
        <div class="post-meta mt-2 mb-2">Code snippets, TILs, notes etc. from my <a href="https://gist.github.com/donbrae">GitHub Gist profile</a>.</div>
        <div class="gists gists-more-space">
          ${items.join('')}
        </div>
      `;

      gists.innerHTML = html;

      const moreGists = document.getElementById('more-gists');
      if (moreGists) {
        moreGists.addEventListener('click', e => {
          document.querySelectorAll('.gists > div.hide').forEach(hiddenItem => {
            hiddenItem.classList.remove('hide');
            setTimeout(() => {
              hiddenItem.classList.add('show');
            }, 60);
          });

          e.target.parentNode.removeChild(e.target);
        });
      }

      const getGists = document.querySelectorAll('.get-gist');
      getGists.forEach(getGistButton => {
        getGistButton.addEventListener('click', e => {
          const gistId = e.target.dataset.gistId;
          const btn = e.target;
          btn.disabled = true; // Make sure only one API call is sent
          btn.classList.add('fade');

          fetch(`https://donbrae.co.uk/getgists/fetch-id.php?gist_id=${gistId}`).then(function (response) {

            if (response.ok)
                return response.json();

            return Promise.reject(response);

          }).then(function (data) {
            const el = document.getElementById(`gist-${gistId}`);

            if (data.error) {
              console.error(data.error);
              el.innerHTML = `<div class="gist-content">${escapeHtml(data.error)}</div>`;
              el.classList.remove('hide');
              btn.parentNode.removeChild(btn);
              setTimeout(() => {
                el.classList.add('show');
              }, 20);
              return;
            }

            const gistName = Object.keys(data.files)[0];
            const gist = data.files[gistName];
            let gistContent;

            if (gist.type === 'text/html') {
              gistContent = escapeHtml(gist.content);
            } else if (gist.type === 'text/markdown') {
              const md = new markdownit('default', { html: true });
              gistContent = md.render(gist.content);

              // TODO: transform code blocks within Markdown with hljs
              // console.log(gistContent);
              // var parser = new DOMParser();
              // var htmlDoc = parser.parseFromString(gistContent, 'text/html');
              // hljs.highlightElement(htmlDoc);
            } else {
              gistContent = gist.content;
            }

            if (gist.type !== 'text/markdown') {
              el.insertAdjacentHTML('beforeend', `<pre class="code" role="code">${gistContent}</pre>`);
              hljs.highlightElement(el.querySelector('pre'));
            } else { // Markdown
              el.insertAdjacentHTML('beforeend', `<div>${gistContent}</div>`);
            }

            el.classList.remove('hide');
            btn.parentNode.removeChild(btn);
            setTimeout(() => {
              el.classList.add('show');
            }, 20);
          }).catch(function (err) {
            console.error(err); // Error
          });
        });
      });

      // Show gists
      gists.classList.remove('hide');
      setTimeout(() => {
        gists.classList.add('show');
      }, 60);
    }

    if (document.readyState === 'loading')
      document.addEventListener('DOMContentLoaded', addToUI);
    else // `DOMContentLoaded` has already fired
      addToUI();
  }).catch(function (err) {
    console.error(err);
  });
}
